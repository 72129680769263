import React from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import ButtonMaterial from "@material-ui/core/Button";
import deepOrange from "@material-ui/core/colors/deepOrange";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1rem",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
  },
});

const Button = ({ children, disabled, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <ButtonMaterial
          {...rest}
          color="primary"
          size="large"
          disabled={disabled}
          variant="contained"
        >
          {children}
        </ButtonMaterial>
      </ThemeProvider>
    </div>
  );
};

export default Button;
