import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CheckboxMaterial from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import deepOrange from "@material-ui/core/colors/deepOrange";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 0.9rem;
  }
`;

const OrangeCheckbox = withStyles({
  root: {
    color: deepOrange[400],
    "&$checked": {
      color: deepOrange[600],
    },
  },
  checked: {},
})((props) => <CheckboxMaterial color="default" {...props} />);

const Checkbox = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <FormControl
      required
      error={errors[field.name] && touched[field.name]}
      component="fieldset"
    >
      <StyledFormControlLabel
        control={<OrangeCheckbox {...props} {...field} />}
        label={props.label}
      ></StyledFormControlLabel>
      <FormHelperText>*Requerido</FormHelperText>
    </FormControl>
  );
};

export default Checkbox;
