import styled from "styled-components";
import { Form } from "formik";

export const StyleForm = styled(Form)`
  display: flex;
  margin-bottom: 2%;
  margin-left: 3%;
  margin-right: 3%;
  flex-direction: column;
  justify-content: center;
`;
