import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      paddingTop: "0.2rem",
      marginBottom: "0.5rem",
      paddingBottom: "0.8rem",
      width: "100%",
    },
  },
}));
const MenuPopupState = ({ field, form: { touched, errors }, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <InputLabel id="demo-controlled-open-select-label">
        ¿Por que medio recibió la invitación?*
      </InputLabel>
      <Select>
        <option hidden>Display but don't show in list</option>
        <option value={"Sánchez Devanny"}>Sánchez Devanny</option>
        <option value={"Ergosolar"}>ErgoSolar</option>
      </Select>
      <FormHelperText>*Requerido</FormHelperText>
    </div>
  );
};
export default MenuPopupState;
